/***
  -==-
  -= Style Sheet for javoblar.uz react-app
  -= version: 1.0.1
  -= last modified: 03.05.2022 by Shukrullo Ibrohimov
  -= author: Suhkrullo Ibrohimov
  -= email: masalanmisoluchun@gmail.com
  -= telegram: @ishukrulloi
  -==-
***/

#root {
  background-color : #b0bec5;
  min-height: 100vh;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
[id^=react-select][class$=-menu] {
  z-index: 99999 !important;
}
a {
  text-decoration: none;
}
.shown {
  display: flex;
}
.hidden {
  display: none !important;
}